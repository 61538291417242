import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import ReactModal from 'react-modal';
import Button from '../button';

const buttonInlineStyle = {fontSize: 12, width: 'auto', marginLeft: 10, padding: 5};
const buttonStyle = {fontSize: 12, width: 140, marginRight: 10};
const formatter = new Intl.NumberFormat('it-IT', {
	style: 'currency',
	currency: 'EUR',
	minimumFractionDigits: 2
})
const format = (price) => {
	return formatter.format(price);
}

const Modal = ({modalOpen, data, click, closeModal, acceptAllOptionalChange}) => (
	<div className={style.modalWrap}
        style={{display: modalOpen? 'block' : 'none'}}>
        <div style={{ padding: 20, background: 'white', border: '3px solid var(--primary-color)', borderRadius: 4 }}>
            <p>E' necessario effettuare delle modifiche agli accessori</p>
            <h3>{data.title}</h3>
            <div>
                <ul>
                    {
                        data && data.itemlist ? data.itemlist
                            .sort((a,b) => a.description.localeCompare(b.description))
                            .map(i => {
                            return (
                                <li key={i.idEquipment}>
                                    <span >{i.description}</span> 
                                    <span style={{marginLeft: 6}}>({format(i.basePrice)})</span>
                                    {
                                        data.action && <button 
                                            id={i.idEquipment}
                                            style={buttonInlineStyle}
                                            className={"fucsia"} 
                                            onClick={click || (e => console.log('placeholder',e)) }>
                                            {data.action}
                                        </button>
                                    }
                                </li>
                            )
                        }) : null
                    }
                </ul>
            </div>
            {
                !data.action && <button 
                    id={'doAction'}
                    style={buttonStyle}
                    className={"fucsia"} 
                    onClick={acceptAllOptionalChange || (e => console.log('placeholder',e)) }>
                    {data.title}
                </button>
            }
            <button 
                id={'chiudi'}
                style={buttonStyle}
                className={"fucsia outline"} 
                onClick={closeModal || (e => console.log('placeholder',e)) }>
                Chiudi
            </button>
        </div>
    </div>
);

export default Modal;
