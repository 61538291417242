import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import logo from '../../assets/logo-urcar-payoff.svg'
import { version } from '../../../package.json'

const Header = () => (
	<header class={style.header}>
		<h1><img style={style.header} src={logo} /></h1>
		<div class={style.buildName}>v{version}</div>
	</header>
);

export default Header;
