
export const prepareOptionals = (car) => {
    if(!car || !car.equipments) return null;
    const outdata = [];
    Object.keys(car.equipments).map(group => {
        const cats = car.equipments[group];
        let found = 0;
        for(const x in car.equipments[group]){
            const equips = car.equipments[group][x];
            found += equips.filter(equip => equip.type !== "STANDARD").length;
        }
        if(found>0) {
            const eitem = {group, categories: []}
            Object.keys(cats).map(item => {
                const equips = car.equipments[group][item];
                const optionals = equips.filter(equip => equip.type !== "STANDARD");
                if(optionals.length>0) {
                    eitem.categories.push({cat: item, optionals});
                }
            });
            if(eitem.categories.length > 0){
                eitem.categories.sort((a,b) => a.cat.localeCompare(b.cat))
                outdata.push(eitem);
            }
        }
    })
    outdata.sort((a,b) => a.group.localeCompare(b.group))
    return outdata;
}

export const filterAddedOptionals = (ids, car) => {
    const optionals = prepareOptionals(car);
    // console.log("->opts",optionals)
    const outdata = [];
    for(const z in optionals) {
        const group = optionals[z];
        for(const y in group.categories) {
            const cat = group.categories[y]
            for(const x in cat.optionals) {
                if(ids.includes(parseInt(cat.optionals[x].idEquipment))){
                    outdata.push(cat.optionals[x])
                }
            }
        }
    }
    return outdata;
}

export const createOptionalsHash = (equips, raw) => {
    const code = equips.map(i => i.manufacturerCode || i.manufactorCode)
        .sort((i,j) => i>j ? 1 : i===j? 0 : -1);
    if(raw === true) return code.join("|");
    return btoa(code.join("|"));
}

export const decodeOptionalsHash = (string, car) => {
    const opts = atob(string).split("|")
    let allopts = [];
    // console.log("car equip", car?.equipments)
    if(!car) return false
    for(const i in car.equipments) {
        const mainp = car.equipments[i]
        // console.log("Parsing", mainp)
        for(const sect in mainp) {
            let found = mainp[sect].filter(i => opts.includes(i.manufacturerCode)).map(i => i.idEquipment)
            if(found.length) {
                // console.log('found',found)
                allopts = allopts.concat(found)
            }
        }
    }
    
    if(allopts.length) {
        const selectedEquip = filterAddedOptionals(allopts.map(i => parseInt(String(i))), car)
        console.log({ selectedEquip, equipstring: string })
        return { selectedEquip, equipstring: string };
    } else {
        console.log("no equip found")
    }
}

export const parseEquipments = (conf,json) => {
    const decs = Object.keys(conf.alternativesAsEquipmentIdTree);
    console.log('ParseEquipments',decs,conf)

    let modalData;
    if(decs.length > 0){
        console.log('decision', decs[0], conf.alternatives.decision[decs[0]])
        const out = conf.alternatives.decision[decs[0]].map(i => {
            const itemToAdd = i.value || i.values[0];
            itemToAdd.idEquipment = parseInt(itemToAdd.idEquipment);
            itemToAdd.type = "OPTION";
            return itemToAdd;
        });

        switch(decs[0]) {
            case "ADD_ALL":
                modalData = {
                    action: null,
                    title: 'Aggiungi tutti',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            case "REMOVE_ALL":
                modalData = {
                    action: null,
                    title: 'Rimuovi tutti',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            case "ADD_ONE_OF":
                modalData = {
                    action: 'Aggiungi',
                    title: 'Aggiungi uno tra',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            case "REMOVE_ONE_OF":
                modalData = {
                    action: 'Rimuovi',
                    title: 'Rimuovi uno tra',
                    itemlist: out,
                    dec: decs[0],
                    request: json
                }
                break;
            default: break;
        }
    }
    return modalData;
}

export default {
    filterAddedOptionals,
    prepareOptionals,
    createOptionalsHash,
    decodeOptionalsHash
}